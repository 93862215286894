<template>
  <div class="cws-container cws-sidebar-right">
    <!-- content -->
    <div v-if="this.$router.currentRoute.name === 'projects'" class="cws-content custom-scrollbar select-type-project">
      <b-card class="timeseries-card custom-card">
        <b-row class="flex-nowrap d-flex align-items-center">
          <b-col cols="auto">
            <radio-group :toggle="true" :options="typeProject" v-model="selectOption" />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="cws-content custom-scrollbar">
      <slot />
    </div>

    <!-- Sidebar -->
    <div class="cws-sidebar">
      <slot name="sidebar" />
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormGroup, BButton } from 'bootstrap-vue'
import RadioGroup from '../../../../views/ecoplot-desktops/common/RadioGroup.vue'

export default {
  components: { BCard, BRow, BCol, BFormGroup, BButton, RadioGroup },
  data() {
    return {
      selectOption: 'myProject',
    }
  },
  computed: {
    typeProject() {
      return [
        { text:this.$t('my_projects'), value: 'myProject' },
        { text: this.$t('shared_with_me'), value: 'shareWithMe' },
      ]
    },
  },
  watch: {
    selectOption(value) {
      this.$emit('changeOption', value)
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/base/bootstrap-extended/include';

$cws-sidebar-width: 260px;
$gap: 2rem;

.cws-container {
  position: relative;

  .cws-content {
    width: calc(100% - #{$cws-sidebar-width} - #{$gap});
    margin-left: auto;
    height: calc(100vh - 170.3px - 1rem);
    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: (map-get($grid-breakpoints, lg))) {
      width: 100%;
    }
  }

  .cws-sidebar {
    width: 260px;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: (map-get($grid-breakpoints, lg))) {
      margin-top: $gap;
      position: static;
      width: 100%;
    }
  }
  .select-type-project {
    height: 80px;
  }
  .select-type-project .card-body {
    padding: 0px !important;
  }
  .select-type-project .card {
    background: transparent;
  }
}
</style>
